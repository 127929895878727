var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('div', {
    staticClass: "text-h5"
  }, [_vm._v("About")]), _c('p', {
    staticClass: "ml-3"
  }, [_vm._v(" A tool for producing Lancer Content Packages for use with "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://compcon.app"
    }
  }, [_vm._v("COMP/CON")]), _vm._v(" . More information about LCPs, including technical notes and content examples, can be found in the "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://github.com/massif-press/lancer-data"
    }
  }, [_vm._v(" lancer-data repository ")])]), _c('v-divider', {
    staticClass: "my-2"
  }), _c('div', {
    staticClass: "text-h5"
  }, [_vm._v("Disclaimer")]), _c('p', {
    staticClass: "ml-3"
  }, [_vm._v(" This app is experimental, subject to frequent and/or profound change, and is only lightly tested. It is provided as-is, but the author makes no guarantees to its operation nor to the output it produces. That said, it is an "), _c('a', {
    attrs: {
      "href": "https://github.com/massif-press/cc-lcp-editor",
      "target": "_blank"
    }
  }, [_vm._v(" open source project ")]), _vm._v(" and contributions and forks are very welcome. Discussion regarding this app should be held in the the #compcon-homebrew channel on the official LANCER Discord. ")]), _c('div', {
    staticClass: "text-h5"
  }, [_vm._v("Issue Reporting")]), _c('p', {
    staticClass: "ml-3"
  }, [_vm._v(" Bugs/feature requests should "), _c('strong', [_vm._v("not")]), _vm._v(" be reported through the COMP/CON form. Instead, create a ticket "), _c('a', {
    attrs: {
      "href": "https://github.com/massif-press/cc-lcp-editor/issues",
      "target": "_blank"
    }
  }, [_vm._v(" in the repository ")])]), _c('div', {
    staticClass: "text-h5"
  }, [_vm._v("Contributors")]), _c('p', {
    staticClass: "ml-3"
  }, [_c('a', {
    attrs: {
      "href": "http://jarena3.github.io/",
      "target": "_blank"
    }
  }, [_vm._v("beeftime")]), _vm._v(" , Bluhman ")]), _c('div', {
    staticClass: "text-h5"
  }, [_vm._v("Special Thanks")]), _c('p', {
    staticClass: "ml-3"
  }, [_vm._v("Bluhman, sealtrowitz, BoltsJ, GNUtopia")]), _c('v-divider', {
    staticClass: "my-2"
  }), _c('div', {
    staticClass: "text-h5"
  }, [_vm._v("Changelog")]), _c('div', {
    staticClass: "text-h6 ml-3"
  }, [_vm._v("v0.1.0 (10/29/2021)")]), _c('p', {
    staticClass: "ml-6"
  }, [_vm._v("Initial release")]), _c('div', {
    staticClass: "text-h6 ml-3"
  }, [_vm._v("v0.1.1 (11/2/2021)")]), _c('div', {
    staticClass: "ml-6"
  }, [_c('ul', [_c('li', [_vm._v(" Corrected Name/Type linkage on System Editor "), _c('a', {
    attrs: {
      "href": "https://github.com/massif-press/cc-lcp-editor/issues/1",
      "target": "_blank"
    }
  }, [_vm._v("(#1)")])]), _c('li', [_vm._v(" Ensure sub-editors clear on exit/load "), _c('a', {
    attrs: {
      "href": "https://github.com/massif-press/cc-lcp-editor/issues/5",
      "target": "_blank"
    }
  }, [_vm._v("(#5)")])]), _c('li', [_vm._v(" Replace tiptap paragraph output with divs to preserve C/C styling "), _c('a', {
    attrs: {
      "href": "https://github.com/massif-press/cc-lcp-editor/issues/7",
      "target": "_blank"
    }
  }, [_vm._v("(#7)")])]), _c('li', [_vm._v(" Collect no-source equipment for integrated etc. \"unlisted\" assignment "), _c('a', {
    attrs: {
      "href": "https://github.com/massif-press/cc-lcp-editor/issues/6",
      "target": "_blank"
    }
  }, [_vm._v("(#6)")])])])]), _c('div', {
    staticClass: "text-h6 ml-3"
  }, [_vm._v("v0.1.2 (11/3/2021)")]), _c('div', {
    staticClass: "ml-6"
  }, [_c('ul', [_c('li', [_vm._v(" Corrected Auxiliary weapon size value "), _c('a', {
    attrs: {
      "href": "https://github.com/massif-press/cc-lcp-editor/issues/9",
      "target": "_blank"
    }
  }, [_vm._v("(#9)")])]), _c('li', [_vm._v(" Add Exotic Gear support "), _c('a', {
    attrs: {
      "href": "https://github.com/massif-press/cc-lcp-editor/issues/8",
      "target": "_blank"
    }
  }, [_vm._v("(#8)")])]), _c('li', [_vm._v(" Corrected incorrect indexing that lead to undeletable items "), _c('a', {
    attrs: {
      "href": "https://github.com/massif-press/cc-lcp-editor/issues/10",
      "target": "_blank"
    }
  }, [_vm._v(" (#10) ")])]), _c('li', [_vm._v(" Fixed array export for integrated and special equipment "), _c('a', {
    attrs: {
      "href": "https://github.com/massif-press/cc-lcp-editor/issues/11",
      "target": "_blank"
    }
  }, [_vm._v(" (#11) ")])])])]), _c('div', {
    staticClass: "text-h6 ml-3"
  }, [_vm._v("v0.1.3 (11/5/2021)")]), _c('div', {
    staticClass: "ml-6"
  }, [_c('ul', [_c('li', [_vm._v(" Added Image/Color picking tools (thanks Bluhman!) "), _c('a', {
    attrs: {
      "href": "https://github.com/massif-press/cc-lcp-editor/pull/13",
      "target": "_blank"
    }
  }, [_vm._v("(#13)")])]), _c('li', [_vm._v(" Added support for tags and alternate types on weapon profiles "), _c('a', {
    attrs: {
      "href": "https://github.com/massif-press/cc-lcp-editor/issues/15",
      "target": "_blank"
    }
  }, [_vm._v(" (#15) ")])])])]), _c('div', {
    staticClass: "text-h6 ml-3"
  }, [_vm._v("v0.1.4 (11/6/2021)")]), _c('div', {
    staticClass: "ml-6"
  }, [_c('ul', [_c('li', [_vm._v(" Fixed color picker not picking dark color "), _c('a', {
    attrs: {
      "href": "https://github.com/massif-press/cc-lcp-editor/issues/16",
      "target": "_blank"
    }
  }, [_vm._v(" (#16) ")])]), _c('li', [_vm._v(" Improve item sorting to prevent the app from assigning the incorrect editor to an item "), _c('a', {
    attrs: {
      "href": "https://github.com/massif-press/cc-lcp-editor/issues/17",
      "target": "_blank"
    }
  }, [_vm._v(" (#17) ")])])])]), _c('div', {
    staticClass: "text-h6 ml-3"
  }, [_vm._v("v0.1.5 (11/23/2021)")]), _c('div', {
    staticClass: "ml-6"
  }, [_c('ul', [_c('li', [_vm._v(" Fixed License page not allowing for new equipment on core manufacturers in an empty LCP "), _c('a', {
    attrs: {
      "href": "https://github.com/massif-press/cc-lcp-editor/issues/19",
      "target": "_blank"
    }
  }, [_vm._v(" (#19) ")])])])]), _c('div', {
    staticClass: "text-h6 ml-3"
  }, [_vm._v("v0.2 (1/25/2022)")]), _c('div', {
    staticClass: "ml-6"
  }, [_c('ul', [_c('li', [_vm._v("First draft implementation of NPC editors")]), _c('li', [_vm._v("Added Community LCP Metadata update tool")]), _c('li', [_vm._v(" Correct Changelog dates "), _c('a', {
    attrs: {
      "href": "https://github.com/massif-press/cc-lcp-editor/issues/21",
      "target": "_blank"
    }
  }, [_vm._v(" (#21) ")])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }